@import '@/components/mixins.scss';

.tooltip {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @include transition-middle();
}

:global(.tablet) {
  display: inline-block;
  vertical-align: middle;
  width: rem(25);
  height: rem(8);
  border-radius: 4px;
  background-color: $gray-5;
}
