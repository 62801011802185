@import '@/components/mixins.scss';

.progressIcon {
  width: rem(50);
  height: rem(50);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;

  i {
    vertical-align: middle;
    line-height: rem(50);
  }
}
