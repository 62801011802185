@import '@/components/mixins.scss';

.mapContainer {
  max-width: rem(400);
  margin: 0 auto;
}

.table {
  white-space: nowrap !important;
  :global(.ant-table-body) {
    overflow-x: auto !important;
  }
}

.textDivider {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: $gray-2;
  }

  &Content {
    flex-shrink: 0;
    padding-left: rem(24);
    padding-right: rem(24);
  }
}
