@import '@/components/mixins.scss';

.donut {
  display: inline-block;
  width: rem(16);
  height: rem(16);
  border-radius: 100%;
  border: 4px solid $default;
  position: relative;
  top: rem(2);
  margin-right: rem(4);
  background-color: $white;

  &.md {
    width: rem(26);
    height: rem(26);
  }
  &.default {
    border-color: $default;
  }
  &.primary {
    border-color: $primary;
  }
  &.secondary {
    border-color: $secondary;
  }
  &.success {
    border-color: $success;
  }
  &.warning {
    border-color: $warning;
  }
  &.danger {
    border-color: $danger;
  }
  &.info {
    border-color: $info;
  }
  &.yellow {
    border-color: $yellow;
  }
  &.orange {
    border-color: $orange;
  }
  &.gray2 {
    border-color: $gray-2;
  }
}
